<template>
  <div class="container pbs">
    <div class="area-content mb8 pt12 pb12 pl15 pr15 bgf fs14">
      <CustomerTitle class="mb16" title="基本信息" />
      <div class="centent-cell flex flex-align-center txt-line-1 mb12">
        <span class="label flex-s0">UnionId:</span>
        <span>{{ reacData.userInfo.unionID || '--' }}</span>
      </div>
      <div class="centent-cell flex flex-align-center txt-line-1 mb12">
        <span class="label flex-s0">UserId:</span>
        <span>{{ reacData.userInfo.userID || '--' }}</span>
      </div>
      <div class="centent-cell flex flex-align-center mb12">
        <span class="label flex-s0">最早添加:</span>
        <div
          v-if="reacData.userInfo.qw_name"
          class="flex flex-align-center cl17 flex-1 txt-line-1"
        >
          <img
            class="avatar32"
            :src="reacData.userInfo.qw_avatar || defaultAvatar"
          />
          <div class="flex-1 txt-line-1">
            <span>{{ reacData.userInfo.qw_name || '' }}</span>
            <span v-if="reacData.userInfo.qw_department_name">/</span>
            <span>
              {{ reacData.userInfo.qw_department_name || '' }}
            </span>
          </div>
        </div>
        <span v-else>--</span>
      </div>
      <div class="centent-cell flex flex-align-center txt-line-1">
        <span class="label flex-s0">添加时间:</span>
        <span>
          {{ reacData.userInfo.create_time || '--' }}
        </span>
      </div>
    </div>
    <!-- 客户资料 - 跟进成员 -->
    <div class="area-content mb8 pl15 pr15 bgf fs14">
      <van-collapse v-model="reacData.activeCollNum">
        <van-collapse-item name="member">
          <template #title>
            <div class="txt-line-1">
              <CustomerTitle title="跟进成员" />
            </div>
          </template>
          <template #value>
            <div class="fs12 cl4a">
              共有
              <span class="txt-blue">
                {{ reacData.trackingTotal || 0 }}
              </span>
              个成员
            </div>
          </template>
          <template #right-icon>
            <div class="collapse-right-icon" />
          </template>
          <van-collapse v-model="reacData.activeCollNum">
            <van-collapse-item
              v-for="(member, index) in reacData.memberList"
              :key="index"
              :name="`member-${index}`"
            >
              <template #title>
                <div class="flex flex-align-center cl17">
                  <img
                    class="avatar32"
                    :src="member.qw_avatar || defaultAvatar"
                  />
                  <div class="flex-1 txt-line-1">
                    <span>
                      {{ member.qw_name || '' }}
                    </span>
                    <span v-if="member.qw_department_name">/</span>
                    <span>
                      {{ member.qw_department_name || '' }}
                    </span>
                  </div>
                </div>
              </template>
              <template #right-icon>
                <div class="collapse-right-icon flex-s0" />
              </template>
              <div class="centent-cell flex flex-align-center txt-line-1 mb12">
                <span class="label flex-s0">添加来源:</span>
                <span>
                  {{ sourceEnum[member.qw_add_way] || '--' }}
                </span>
              </div>
              <div class="centent-cell flex flex-align-center txt-line-1 mb12">
                <span class="label flex-s0">客户状态:</span>
                <span>
                  {{ clientStatuEnum[member.deleted_state] || '--' }}
                </span>
              </div>
              <div class="centent-cell flex flex-align-center txt-line-1 mb12">
                <span class="label flex-s0">备注手机号:</span>
                <span>{{ member.qw_remark_mobiles || '--' }}</span>
              </div>
              <div class="centent-cell flex flex-align-center txt-line-1 mb12">
                <span class="label flex-s0">添加时间:</span>
                <span>{{ member.qw_createtime || '--' }}</span>
              </div>
              <div class="centent-cell flex flex-align-center txt-line-1 mb12">
                <span class="label flex-s0">备注名:</span>
                <span>{{ member.qw_remark || '--' }}</span>
              </div>
              <div class="centent-cell flex flex-align-center txt-line-1 mb12">
                <span class="label flex-s0">备注企业:</span>
                <span>
                  {{ member.qw_remark_corp_name || '--' }}
                </span>
              </div>
              <div class="centent-cell flex flex-direction-column mb12">
                <span class="label mb5">备注描述:</span>
                <span>{{ member.qw_description || '--' }}</span>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 客户资料 - 客户所在群 -->
    <div class="area-content pl15 pr15 bgf fs14">
      <van-collapse v-model="reacData.activeCollNum">
        <van-collapse-item name="group">
          <template #title>
            <div class="txt-line-1">
              <CustomerTitle title="客户所在群" />
            </div>
          </template>
          <template #value>
            <div class="fs12 cl4a">
              共有
              <span class="txt-blue">
                {{ reacData.groupTotal || 0 }}
              </span>
              个群
            </div>
          </template>
          <template #right-icon>
            <div class="collapse-right-icon" />
          </template>
          <div
            v-for="(group, index) in reacData.userGroupList"
            :key="group.group_id"
            class="pt12 pb12"
            :class="{
              bdb: index !== reacData.userGroupList.length - 1
            }"
          >
            <div class="mb12 fw500">
              <span>{{ group.group_name || '群聊' }}</span>
              ( 共
              <span class="txt-blue fs16">
                {{ group.count || 0 }}
              </span>
              人 )
            </div>
            <div class="flex flex-align-center fs12 cl17">
              <span v-if="group.user_role">{{ group.user_role }}：</span>
              <img class="avatar32" :src="group.user_avatar || defaultAvatar" />
              <div class="flex-1 txt-line-1">
                <span>
                  {{ group.user_name || '' }}
                </span>
                <span v-if="group.member_department">/</span>
                <span>
                  {{ group.member_department || '' }}
                </span>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue'
import CustomerTitle from '@/components/customerTitle/index.vue'
import {
  getDetailUserInfo,
  getUserGroupList
} from '@/service/customer.service.js'
import { defaultAvatar, sourceEnum, clientStatuEnum } from '@/utils/constants'

const props = defineProps({
  // 企微类型主体信息
  dataSource: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: () => false
  }
})
const reacData = reactive({
  userInfo: {}, // 客户详情
  memberList: [], // 客户跟进成员
  trackingTotal: 0, // 跟进成员总数
  userGroupList: [], // 客户所在群
  groupTotal: 0, // 客户所在群总数
  activeCollNum: ['member', 'group'] // 当前展开的折叠面板
})

watch(
  () => props.visible,
  (newval) => {
    if (newval) {
      initData()
    }
  }
)

onMounted(() => {
  initData()
})

const initData = () => {
  const { userInfo, memberList, userGroupList } = reacData
  if (!userInfo.unionID || !memberList.length || !userGroupList.length) {
    getUserInfo()
    getGroupList()
  }
}

// 获取客户详情
const getUserInfo = async () => {
  try {
    const { subject_data_id } = props.dataSource
    const res = await getDetailUserInfo(subject_data_id)

    if (res.code === 200) {
      reacData.userInfo = res.data.info
      reacData.memberList = res.data.tracking
      reacData.trackingTotal = res.data.tracking_total
    }
  } catch (error) {
    console.log(error)
  }
}
// 获取客户所在群
const getGroupList = async () => {
  try {
    const { yw_id } = props.dataSource
    const res = await getUserGroupList(yw_id)

    if (res.code === 200) {
      reacData.userGroupList = res.data.list
      reacData.groupTotal = res.data.count
    }
  } catch (error) {
    console.log(error)
  }
}
</script>

<style lang="less" type="text/less" scoped>
.avatar32 {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 6px;
}

.container {
  height: 100%;
  overflow: scroll;

  .area-content {
    :deep(.van-collapse) {
      .van-collapse-item {
        border-bottom: 1px solid #edeef2;

        .van-collapse-item__title {
          height: 52px;
          display: flex;
          align-items: center;
          padding: 0;

          .van-cell__title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .van-cell__value {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .van-collapse-item__content {
          padding: 0;
          color: #000;
        }
      }
      .van-collapse-item::after,
      .van-cell::after {
        border: none;
      }
      .van-collapse-item:last-child {
        border: none;
      }
    }

    :deep(.van-hairline--top-bottom::after) {
      border: none;
    }

    .collapse-right-icon {
      margin-left: 7px;
      display: inline-block;
      width: 0px;
      height: 0px;
      border-bottom: none;
      border-top: 6px solid #999999;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    :deep(.van-collapse-item__title--expanded) {
      .collapse-right-icon {
        border-bottom: 6px solid #999999;
        border-top: none;
      }
    }
  }
}
</style>
