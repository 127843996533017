<template>
  <div class="customer-title">
    <span class="vtm">
      {{ title }}
    </span>
    <span>
      {{ content }}
    </span>
    <img src="./customerTitle.png" alt="" class="title-img" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题'
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.customer-title {
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  /* padding-bottom: 16px; */
  line-height: 20px;
}

.title-img {
  width: 53px;
  height: 6px;
  vertical-align: middle;
}
</style>
