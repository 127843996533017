<template>
  <div class="container">
    <div class="fill-content bgf pbs">
      <div class="area-content pt12 pb12 pl15 pr15 bgf">
        <CustomerTitle class="mb16" title="基本信息" />
        <div
          v-for="(item, index) in userInfo"
          :key="index"
          class="centent-cell flex flex-align-center txt-line-1 mb12"
        >
          <span class="label flex-s0">{{ item.label }}:</span>
          <span>{{ item.content || '--' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref } from 'vue'
import CustomerTitle from '@/components/customerTitle/index.vue'
import { getDetailUserInfo } from '@/service/customer.service.js'

const props = defineProps({
  // 有赞类型主体信息
  dataSource: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: () => false
  }
})
const userInfo = ref([]) // 客户详情

watch(
  () => props.visible,
  (newval) => {
    if (newval) {
      initData()
    }
  }
)
onMounted(() => {
  initData()
})
const initData = () => {
  if (!userInfo.value.length) {
    getUserInfo()
  }
}
// 获取客户详情
const getUserInfo = async () => {
  try {
    const { subject_data_id } = props.dataSource
    const res = await getDetailUserInfo(subject_data_id)

    if (res.code === 200) {
      userInfo.value = res.data.info
    }
  } catch (error) {
    console.log(error)
  }
}
</script>

<style lang="less" type="text/less" scoped>
.fill-content {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
</style>
